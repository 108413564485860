import {
  Alert,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
} from "@biblioteksentralen/react";
import { RediaPlatformConfigurationApiError, RediaPlatformPatronApiError } from "@libry-content/redia-platform";
import * as Sentry from "@sentry/nextjs";
import { FormEventHandler, useState } from "react";
import useSWRImmutable from "swr/immutable";
import { ZodError, z } from "zod";
import { Edit } from "../components/editInSanity/EditInSanity";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { ShowForGlobalAdmins } from "../utils/ShowForGlobalAdmins";
import { useFeatureToggle } from "../utils/hooks/featureToggles";
import { useLibrarySystemClient } from "../utils/hooks/useLibrarySystemClient";
import { Translate, useTranslation } from "../utils/hooks/useTranslation";
import { GoToOldMinSideModal } from "./GoToOldMinSideModal";
import { useRediaPlatformContext } from "./RediaPlatformProvider";

const credentialsSchema = (t: Translate["t"]) =>
  z.object({
    username: z.string().min(1, t("Du må fylle inn bibliotekkortnummer, telefon eller e-post.")),
    password: z.string().min(1, t("Du må fylle inn passord.")),
  });
type Credentials = z.infer<ReturnType<typeof credentialsSchema>>;

const isRediaAdminConfigError = (error: Error | undefined) =>
  error instanceof RediaPlatformPatronApiError && error.code === 100;

const isInvalidCredentials = (error: Error | undefined) =>
  error instanceof RediaPlatformPatronApiError && error.code === 1001;

const isLibraryNumberError = (error: Error | undefined) => error instanceof Error && error.cause === "isil";

const isValidLibraryNumber = async (libraryNumber: string) => {
  const res = await fetch(`https://www.nb.no/basebibliotek/rest/bibnr/${libraryNumber}?format=json`);
  if (!res.ok) return false;
  return true;
};

const renderLoginError = (error: Error, t: Translate["t"]) => {
  if (error instanceof RediaPlatformConfigurationApiError && error.code === 150) {
    return t("Innlogging ikke mulig fordi nettstedet er konfigurert med en ugyldig Redia Admin Kunde-ID.");
  }

  if (isRediaAdminConfigError(error)) {
    return t("Innlogging ikke mulig fordi det mangler oppsett i Redia Admin (feilkode 100).");
  }

  if (isLibraryNumberError(error)) {
    return t("Kan ikke logge inn som ansatt");
  }

  if (isInvalidCredentials(error)) {
    return t("Feil bibliotekkortnummer, telefon, e-post eller passord.");
  }

  if (error instanceof RediaPlatformPatronApiError) {
    console.error(`Innlogging feilet på grunn av Redia Platform-feil ${error.code}`, error);
    return (
      t("Beklager, vi har tekniske problemer akkurat nå (Feilkode: {errorCode}).", { errorCode: error.code }) +
      " " +
      t("Prøv igjen senere.")
    );
  }

  if (error instanceof TypeError) {
    return t("Ingen internettforbindelse.");
  }

  console.error("Innlogging feilet med ukjent feil", error);
  return t("Beklager, det oppsto en ukjent feil.");
};

export const LoginForm = () => {
  const { site } = useCommonData();
  const { rediaPlatform, isRediaPlatformConfigured, isSessionReady, lastSessionExpired } = useRediaPlatformContext();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<Error | undefined>(undefined);
  const [validation, setValidation] = useState<undefined | ZodError<Credentials>>();
  const [loadingState, setLoadingState] = useState<"idle" | "loading" | "success">("idle");
  const librarySystem = useLibrarySystemClient();
  const newPatronUrl = librarySystem?.getLinkToNewPatron();
  const librarySystemLoginUrl = librarySystem?.getLinkToMyAccount();
  const forgottenPasswordUrl = librarySystem?.getLinkToForgottenPassword();
  const oldMyAccountUrl = librarySystem?.getLinkToMyAccount();
  const customTNBTekst = useFeatureToggle("customTNBTekst");

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    if (loadingState === "loading") return;

    if (!rediaPlatform) {
      console.error("Siden er ikke satt opp for Min side, mangler Redia klient-ID");
      return;
    }

    const validation = credentialsSchema(t).safeParse({
      username: username.trim(),
      password,
    });
    if (!validation.success) {
      setValidation(validation.error);
      return;
    }
    setValidation(undefined);
    setLoginError(undefined);
    setLoadingState("loading");
    try {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Starting login request",
        level: "info",
      });
      await rediaPlatform.login(validation.data.username, validation.data.password);
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Login successful",
        level: "info",
      });
    } catch (error) {
      (await isValidLibraryNumber(validation.data.username))
        ? setLoginError(new Error("Det er ikke støtte for innlogging som ansatt", { cause: "isil" }))
        : setLoginError(error instanceof Error ? error : new Error(JSON.stringify(error)));
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Login failed",
        data: {
          error,
        },
        level: "info",
      });
    } finally {
      setLoadingState("idle");
    }
  };

  if (!isRediaPlatformConfigured) {
    return (
      <Container marginY="4rem">
        <Alert status="warning">
          {t("Integrasjon med biblioteksystemet er ikke ferdig satt opp")}
          {". "}
          <Link href={oldMyAccountUrl}>
            {customTNBTekst ? "Gå til Bibliofil" : t("Gå til den gamle utgaven av Min side")}
          </Link>
          .
        </Alert>
        {site && <Edit marginTop="1rem" doc={site} label="Legg til Redia Admin Kunde-ID" />}
      </Container>
    );
  }

  if (!isSessionReady)
    return (
      <Container maxW="20rem" height="100vh" alignItems="center" justifyContent="center" display="flex">
        <Spinner size="xl" />
      </Container>
    );

  return (
    <Stack marginBottom="4rem" maxWidth="25rem" marginX="auto">
      <Stack as="form" onSubmit={onSubmit} flexGrow="1" gap="1rem">
        {lastSessionExpired?.reason == "inactive_for_too_long" &&
          lastSessionExpired.inactiveTimeSeconds &&
          lastSessionExpired?.inactiveTimeSeconds < 3600 * 8 && (
            <Alert status="info">
              {t("Velkommen tilbake. Du ble logget ut fordi du har vært inaktiv for lenge, men logg gjerne inn igjen.")}
            </Alert>
          )}
        {lastSessionExpired?.reason == "logged_in_for_too_long" && (
          <Alert status="info">
            {t("Du ble logget ut fordi du har vært logget inne for lenge, men logg gjerne inn igjen.")}
          </Alert>
        )}
        <Heading as="h1">{t("Logg inn")}</Heading>
        <Box>
          <Input
            label={t("Bibliotekkortnummer, telefon eller e-post")}
            onChange={(e) => setUsername(e.currentTarget.value)}
            value={username}
            id="username"
            name="username"
            labelProps={{ htmlFor: "username", fontWeight: 600 }}
            autoComplete="username"
            autoFocus
            errorMessage={validation?.formErrors.fieldErrors.username?.join(", ")}
          />
        </Box>
        <Box>
          <Input
            label={t("Passord eller PIN")}
            type="password"
            id="password"
            name="password"
            labelProps={{ htmlFor: "password", fontWeight: 600 }}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.currentTarget.value)}
            value={password}
            errorMessage={validation?.formErrors.fieldErrors.password?.join(", ")}
          />
          <GoToOldMinSideModal
            marginTop=".25rem"
            heading={t("Glemt passord eller PIN?")}
            href={forgottenPasswordUrl}
            bodyText={
              customTNBTekst
                ? "Siden er under utvikling. Du blir derfor sendt til Bibliofil for å endre passord eller PIN. Når du har gjort endringene, kan du gå tilbake hit for å logge inn."
                : t(
                    "Siden er under utvikling. Du blir derfor sendt til den gamle siden for å endre passord eller PIN. Når du har gjort endringene, kan du gå tilbake hit for å logge inn."
                  )
            }
            buttonText={t("Endre passord eller PIN")}
          />
        </Box>
        <Stack>
          <Button
            marginTop="1rem"
            size="lg"
            type="submit"
            isLoading={loadingState === "loading"}
            loadingText="Logger inn"
          >
            {t("Logg inn")}
          </Button>
          {loginError && (
            <Box role="alert" color="red.500" fontWeight={600}>
              {renderLoginError(loginError, t)}
            </Box>
          )}
        </Stack>

        {isLibraryNumberError(loginError) && (
          <Box>
            <Link href={oldMyAccountUrl} target="_blank" alignSelf="flex-end">
              {customTNBTekst ? "Gå til Bibliofil" : t("Gå til den gamle utgaven av Min side")}
            </Link>{" "}
            <Text display="inline">{t("for å logge inn som ansatt")}</Text>
          </Box>
        )}

        {isInvalidCredentials(loginError) && !isLibraryNumberError(loginError) && (
          <Box paddingTop="1rem">
            <Text as="span" fontSize="1rem">
              {t("Prøver du å logge inn med et bibliotekkort som du ikke har brukt på dette biblioteket før?")}
            </Text>{" "}
            <GoToOldMinSideModal
              heading={t("Godkjenn bibliotekets låneregler")}
              bodyText={t(
                "Denne siden er under utvikling. Godkjenning av låneregler må inntil videre gjøres direkte i biblioteksystemet. Etter at du har logget inn der, vil du bli bedt om å lese og godkjenne lånereglene (gitt at biblioteket har åpnet for digital registrering). Etterpå vil du kunne logge inn her."
              )}
              buttonText="Fortsett i ny fane"
              href={librarySystemLoginUrl}
            />
            .
          </Box>
        )}

        {!isLibraryNumberError(loginError) && (
          <Flex gap=".2rem">
            <Text>{t("Har du ikke bibliotekkort?")}</Text>
            <GoToOldMinSideModal
              heading={t("Bli låner")}
              bodyText={
                customTNBTekst
                  ? "Siden er under utvikling. Du blir derfor sendt til Bibliofil for å registrere deg som låner. Når du har blitt låner, kan du gå tilbake hit for å logge inn."
                  : t(
                      "Siden er under utvikling. Du blir derfor sendt til den gamle siden for å registrere deg som låner. Når du har blitt låner, kan du gå tilbake hit for å logge inn."
                    )
              }
              buttonText={t("Bli låner")}
              href={newPatronUrl}
            />
          </Flex>
        )}
      </Stack>
      <DevInfo />
    </Stack>
  );
};

const rediaAdminUrls = {
  production: "https://libry-admin.redia.dk/",
  staging: "https://libry-admin-staging.redia.dk/",
  dev: "https://libry-admin-dev.redia.dk/",
};

const DevInfo = () => {
  const { rediaPlatform, toggleUseMockClient } = useRediaPlatformContext();
  const { site } = useCommonData();
  const rediaAdminUrl = (rediaAdminUrls as any)[rediaPlatform?.environment ?? ""];
  const { data: sessionConfig, error: sessionConfigError } = useSWRImmutable(
    rediaPlatform ? `/redia-configuration${rediaPlatform.isMock ? "-mock" : ""}` : null,
    () => rediaPlatform?.getConfiguration()
  );
  const rediaCustomerName = sessionConfig?.productCode;

  /* eslint i18next/no-literal-string: 0 */
  return (
    <ShowForGlobalAdmins>
      <Stack fontSize="sm" padding=".5rem">
        {rediaPlatform?.isMock ? (
          <Text>
            Integrasjonen mot Redia Platform simuleres med en mock. Du kan logge inn med hva som helst og vil få ut
            testdata, ikke data fra et ekte biblioteksystem.
          </Text>
        ) : (
          <>
            <Link href="https://bs-redia.atlassian.net/l/cp/ud3dsXWA">Testbrukere finnes i Confluence.</Link>
            <Text>
              <em>Redia Platform-innstillinger:</em>
              {site && <Edit marginLeft="0.2rem" doc={site} label="Rediger" />}
              <br />
              Miljø: <strong>{rediaPlatform?.environment}</strong>. Kunde-ID:{" "}
              <strong style={{ whiteSpace: "nowrap" }}>{site?.librarySystem?.rediaPlatformConfig?.customerId}</strong>
              {rediaCustomerName && ` (${rediaCustomerName})`}
            </Text>
            {sessionConfigError && <Text color="red">Redia kunde-ID er ugyldig.</Text>}
            <Text>
              <Link href={rediaAdminUrl}>Gå til Redia Admin ({rediaPlatform?.environment})</Link>
            </Text>
          </>
        )}
        <Link as="button" alignSelf="flex-end" onClick={toggleUseMockClient}>
          Skru {rediaPlatform?.isMock ? "av" : "på"} mock
        </Link>
      </Stack>
    </ShowForGlobalAdmins>
  );
};
